import * as React from "react";
import * as style from "./style.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper';
import "swiper/css";
import 'swiper/css/pagination';

const PlansCustomization = ({datas, isDesktop, handleScroll, countryCode}) => {

    let list = datas.slides.map((slide) => (
            <SwiperSlide className={style.PlansCustomizationSwiper_slide} key={slide.id} data-slide={slide.id}>
                {slide.new &&
                    <div className={style.newOfferTag}>
                        <p className={style.slideTitle} dangerouslySetInnerHTML={{ __html: slide.new}} />
                    </div>
                }

                <div className={style.slideWrapperImage}></div>

                <div className={style.slideContentWrapper}>
                    <p className={style.slideTitle} dangerouslySetInnerHTML={{ __html: slide.title}} />
                    <p className={style.slideDescription} dangerouslySetInnerHTML={{ __html: slide.description}} />
                </div>

                {slide.linkToPage && <a className={style.slideLink} href={`${process.env.GATSBY_SITE_URL}/it/${slide.linkToPage}`} />}
            </SwiperSlide>
        ));

    return (
        <div className={style.sectionPlansCustomization}>
            <div className={style.wrapperSectionTitle}>
                <p dangerouslySetInnerHTML={{ __html: datas.sectionTitle }}/>
            </div>

            <div className={style.containerSwiper}>
                {isDesktop ?
                    <div className={style.dskSlidesContainer}>
                        <div className={style.dskWrapperList}>
                            {list}
                        </div>

                        <a
                            className={style.sectionLink}
                            href={datas.sectionLinkUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                            dangerouslySetInnerHTML={{ __html: datas.sectionLinkText }}
                            onClick={(e) => {
                                e.preventDefault();
                                handleScroll("faq");
                            }}
                        />
                    </div>
                        :
                    <Swiper
                        className={style.PlansCustomizationSwiper}
                        initialSlide={0}
                        slidesPerView={1.2}
                        centeredSlides={true}
                        pagination={{
                            clickable: true
                        }}
                        spaceBetween={16}
                        modules={[Pagination]}
                        breakpoints={{
                            600: {
                                slidesPerView: 2,
                            },
                            1920: {
                                slidesPerView: 3,
                            }
                        }}
                    >
                        {list}

                        <a className={style.sectionLink}
                           rel="noopener noreferrer"
                           target="_blank"
                           dangerouslySetInnerHTML={{ __html: datas.sectionLinkText }}
                           onClick={(e) => {
                            e.preventDefault();
                            handleScroll("faq");
                           }}
                        />
                    </Swiper>
                }
            </div>
        </div>
    )
}

export default PlansCustomization;