// extracted by mini-css-extract-plugin
export var sectionPirelliFamily = "style-module--sectionPirelliFamily--3WKXY";
export var wrapperPirelliFamilyContent = "style-module--wrapperPirelliFamilyContent--qT3Tr";
export var containerImage = "style-module--containerImage--2P8iT";
export var wrapperContent = "style-module--wrapperContent--1hoyl";
export var contentDescription = "style-module--contentDescription--28e_W";
export var contentDescription_logo = "style-module--contentDescription_logo--1avaN";
export var contentDescription_title = "style-module--contentDescription_title--OsX9j";
export var contentDescription_infoText = "style-module--contentDescription_infoText--2YQlE";
export var contentDescription_legalLink = "style-module--contentDescription_legalLink--w_4az";
export var wrapperCta = "style-module--wrapperCta--2BSAw";
export var wrapperCtaContent = "style-module--wrapperCtaContent--3yUjz";