import React from "react";
import * as style from "./style.module.scss";

const CloseIcon = (props) => {
  const { onClick, className } = props;

  return (
    <div className={`${style.closeIcon} ${className}`} onClick={onClick} aria-hidden="true">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M15 5L5 15" stroke="#D52B1E" strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5 5L15 15" stroke="#D52B1E" strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
};
export default CloseIcon;
