// extracted by mini-css-extract-plugin
export var containerChooseService = "style-module--containerChooseService--3QVX5";
export var dskVisualImage = "style-module--dskVisualImage--C_pnO";
export var wrapperTimelines = "style-module--wrapperTimelines--cyGoC";
export var titleContainer = "style-module--titleContainer--KybJE";
export var wrapperTitleMbl = "style-module--wrapperTitleMbl--3iZ_w";
export var wrapperTitleDsk = "style-module--wrapperTitleDsk--2r4yM";
export var wrapperContent = "style-module--wrapperContent--1vd7X";
export var mblTimeline = "style-module--mblTimeline--3SKOR";
export var mblTimelinePath = "style-module--mblTimelinePath--2Pr9i";
export var containerTimelineBoxes = "style-module--containerTimelineBoxes--1ZURm";
export var boxTimelineStep = "style-module--boxTimelineStep--13kyo";
export var contentWrapper = "style-module--contentWrapper--3Uh6J";
export var wrapperImage = "style-module--wrapperImage--3_hij";
export var boxTimelineStep__left = "style-module--boxTimelineStep__left--os8VF";
export var boxTimelineStep__right = "style-module--boxTimelineStep__right--D16fB";
export var dskTimeline = "style-module--dskTimeline--3xlx3";
export var dskTimelineContentWrapper = "style-module--dskTimelineContentWrapper--3eLbC";
export var activeTiresPackage = "style-module--activeTiresPackage--1mWnU";
export var dskTimelinePathImage = "style-module--dskTimelinePathImage--2S1kq";
export var dskFilterPath = "style-module--dskFilterPath--1MYar";
export var dskTimelineBoxes = "style-module--dskTimelineBoxes--1hR7m";
export var timelineImg = "style-module--timelineImg--2wPDZ";
export var text = "style-module--text--1HL60";
export var textTitle = "style-module--textTitle--2vMkQ";
export var textDescription = "style-module--textDescription--2Bgaz";
export var dskFullsizeClickable = "style-module--dskFullsizeClickable--1WsvF";