import * as React from "react";
import * as style from "./style.module.scss";
import "swiper/css";
import "swiper/css/pagination";

const PlansYourDrivingStyle = ({ datas }) => {
  const imagePath = "../../images/";

  return (
    <div className={style.sectionPlansYourDrivingStyle}>
      <div className={style.wrapperSectionTitle}>
        <p dangerouslySetInnerHTML={{ __html: datas.sectionTitle }} />
      </div>
      <div className={style.wrapperSectionDescription}>
        <p dangerouslySetInnerHTML={{ __html: datas.sectionDescription }} />
      </div>
      <div className={style.sectionPlansYourDrivingStyle__row}>
        {datas.slides.map((slide, i) => (
          <div key={i} className={style.sectionPlansYourDrivingStyle__box}>
            <div className={style.sectionPlansYourDrivingStyle__box__img}>
              <img src={`${slide.img}`} alt={slide.title} />
            </div>
            <p
              className={style.sectionPlansYourDrivingStyle__box__title}
              dangerouslySetInnerHTML={{ __html: slide.title }}
            ></p>
            <p
              className={style.sectionPlansYourDrivingStyle__box__description}
              dangerouslySetInnerHTML={{ __html: slide.description }}
            ></p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlansYourDrivingStyle;
