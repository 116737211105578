import * as React from "react";
import * as style from "./style.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { gsap } from "gsap";

const ChooseServices = ({ mobileUp, chooseServices }) => {
  const imagePath = "../../images/";

  const dskTimeline = React.useRef();
  const timelineSelector = gsap.utils.selector(dskTimeline);

  React.useEffect(() => {
    dskTimeline.current = gsap
      .timeline({ delay: 1 })
      .to(timelineSelector(".dsk-filter-timeline"), { width: "0", duration: 3 })
      .to(
        timelineSelector(".dsk-plan"),
        { top: "2px", opacity: 1, duration: 0.75 },
        "<"
      )
      .to(
        timelineSelector(".dsk-customize"),
        { top: "-16px", opacity: 1, duration: 0.75 },
        "<+=0.6"
      )
      .to(
        timelineSelector(".dsk-confirm"),
        { top: "5px", opacity: 1, duration: 0.75 },
        "<+=0.6"
      )
      .to(
        timelineSelector(".dsk-reservation"),
        { top: "10px", opacity: 1, duration: 0.75 },
        "<+=0.6"
      );
  }, []);

  return (
    <div className={style.containerChooseService}>
      <div className={style.dskVisualImage}>
        <StaticImage
          src="../../images/hero-inner-after-image.png"
          alt="desktop visual image"
          objectFit="contain"
        />
      </div>

      <div className={style.wrapperTimelines}>
        <div className={style.titleContainer}>
          {mobileUp ? (
            <span
              className={style.wrapperTitleDsk}
              dangerouslySetInnerHTML={{
                __html: chooseServices.wrapperCustomServices.title,
              }}
            />
          ) : (
            <p
              className={style.wrapperTitleMbl}
              dangerouslySetInnerHTML={{
                __html: chooseServices.wrapperCustomServices.title,
              }}
            />
          )}
        </div>

        <div className={style.wrapperContent}>
          <div className={style.mblTimeline}>
            <div className={style.mblTimelinePath}></div>

            <div className={style.containerTimelineBoxes}>
              <div
                className={`${style.boxTimelineStep} ${style.boxTimelineStep__left} step-plan`}
              >
                <div className={style.contentWrapper}>
                  <div className={style.wrapperImage}>
                    <StaticImage
                      src={`${imagePath}box-step-plan-icon.png`}
                      alt="step plan icon"
                    />
                  </div>

                  <p
                    dangerouslySetInnerHTML={{
                      __html: chooseServices.tiresAndServices.stepPlan,
                    }}
                  />
                </div>
              </div>

              <div
                className={`${style.boxTimelineStep} ${style.boxTimelineStep__right} step-customize`}
              >
                <div className={style.contentWrapper}>
                  <div className={style.wrapperImage}>
                    <StaticImage
                      src={`${imagePath}box-step-customize-icon.png`}
                      alt="step customize icon"
                    />
                  </div>

                  <p
                    dangerouslySetInnerHTML={{
                      __html: chooseServices.tiresAndServices.stepCustomize,
                    }}
                  />
                </div>
              </div>

              <div
                className={`${style.boxTimelineStep} ${style.boxTimelineStep__left} step-confirm`}
              >
                <div className={style.contentWrapper}>
                  <div className={style.wrapperImage}>
                    <StaticImage
                      src={`${imagePath}box-step-confirm-icon.png`}
                      alt="step confirm icon"
                    />
                  </div>

                  <p
                    dangerouslySetInnerHTML={{
                      __html: chooseServices.tiresAndServices.stepConfirm,
                    }}
                  />
                </div>
              </div>

              <div
                className={`${style.boxTimelineStep}  ${style.boxTimelineStep__right} step-reservation`}
              >
                <div className={style.contentWrapper}>
                  <div className={style.wrapperImage}>
                    <StaticImage
                      src={`${imagePath}box-step-reservation-icon.png`}
                      alt="step reservation icon"
                    />
                  </div>

                  <p
                    dangerouslySetInnerHTML={{
                      __html: chooseServices.tiresAndServices.stepFitting,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={style.dskTimeline} ref={dskTimeline}>
            <div className={style.dskTimelineContentWrapper}>
              <div className={style.activeTiresPackage}>
                <div className={style.dskTimelinePathImage}>
                  <div
                    className={`${style.dskFilterPath} dsk-filter-timeline`}
                  ></div>
                </div>

                <div className={`${style.dskTimelineBoxes} dsk-plan`}>
                  <div className={style.timelineImg}>
                    <StaticImage
                      src={`${imagePath}box-step-plan-icon.png`}
                      alt="step plan icon"
                    />
                  </div>

                  <div className={style.text}>
                    <p
                      className={style.textTitle}
                      dangerouslySetInnerHTML={{
                        __html: chooseServices.tiresAndServices.stepPlan,
                      }}
                    />
                    <p
                      className={style.textDescription}
                      dangerouslySetInnerHTML={{
                        __html:
                          chooseServices.tiresAndServices.stepPlanSubtitle,
                      }}
                    />
                  </div>
                </div>

                <div className={`${style.dskTimelineBoxes} dsk-customize`}>
                  <div className={style.timelineImg}>
                    <StaticImage
                      src={`${imagePath}box-step-customize-icon.png`}
                      alt="step customize icon"
                    />
                  </div>

                  <div className={style.text}>
                    <p
                      className={style.textTitle}
                      dangerouslySetInnerHTML={{
                        __html: chooseServices.tiresAndServices.stepCustomize,
                      }}
                    />
                    <p
                      className={style.textDescription}
                      dangerouslySetInnerHTML={{
                        __html:
                          chooseServices.tiresAndServices.stepCustomizeSubtitle,
                      }}
                    />
                  </div>
                </div>

                <div className={`${style.dskTimelineBoxes} dsk-confirm`}>
                  <div className={style.timelineImg}>
                    <StaticImage
                      src={`${imagePath}box-step-confirm-icon.png`}
                      alt="step confirm icon"
                    />
                  </div>

                  <div className={style.text}>
                    <p
                      className={style.textTitle}
                      dangerouslySetInnerHTML={{
                        __html: chooseServices.tiresAndServices.stepConfirm,
                      }}
                    />
                    <p
                      className={style.textDescription}
                      dangerouslySetInnerHTML={{
                        __html:
                          chooseServices.tiresAndServices.stepConfirmSubtitle,
                      }}
                    />
                  </div>
                </div>

                <div className={`${style.dskTimelineBoxes} dsk-reservation`}>
                  <div className={style.timelineImg}>
                    <StaticImage
                      src={`${imagePath}box-step-reservation-icon.png`}
                      alt="step reservation icon"
                    />
                  </div>

                  <div className={style.text}>
                    <p
                      className={style.textTitle}
                      dangerouslySetInnerHTML={{
                        __html: chooseServices.tiresAndServices.stepFittingDsk,
                      }}
                    />
                    <p
                      className={style.textDescription}
                      dangerouslySetInnerHTML={{
                        __html:
                          chooseServices.tiresAndServices.stepFittingSubtitle,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {mobileUp && (
          <a
            className={style.dskFullsizeClickable}
            href={`${process.env.GATSBY_ONBOARDING_PORTAL_LINK}${chooseServices.wrapperCustomServices.superiorBundle}`}
            target="_blank"
          />
        )}
      </div>
    </div>
  );
};

export default ChooseServices;
