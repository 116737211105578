// extracted by mini-css-extract-plugin
export var sectionPlansCustomization = "style-module--sectionPlansCustomization--7QCKN";
export var wrapperSectionTitle = "style-module--wrapperSectionTitle--AaMzh";
export var containerSwiper = "style-module--containerSwiper--3FD9V";
export var PlansCustomizationSwiper = "style-module--PlansCustomizationSwiper--3Qi8t";
export var PlansCustomizationSwiper_slide = "style-module--PlansCustomizationSwiper_slide--2cL9B";
export var slideWrapperImage = "style-module--slideWrapperImage--2ITHg";
export var newOfferTag = "style-module--newOfferTag--fi8zh";
export var slideContentWrapper = "style-module--slideContentWrapper--P3gcW";
export var slideTitle = "style-module--slideTitle--3BNjH";
export var slideDescription = "style-module--slideDescription--Cm6bo";
export var slideLink = "style-module--slideLink--lGZYb";
export var sectionLink = "style-module--sectionLink--tGzV4";
export var dskSlidesContainer = "style-module--dskSlidesContainer--2_eCm";
export var dskWrapperList = "style-module--dskWrapperList--3c7fQ";