import * as React from "react";
import * as style from "./style.module.scss";
import ArrowRight from "../Icons/ArrowRight";
import {StaticImage} from "gatsby-plugin-image";
import MobileAppDownload from "../MobileAppDownload";
import video from "../../images/pcare_previewvideo.mp4";
import videoMbl from "../../images/pcare_previewvideo_mbl.mp4";
import videoIt from "../../images/pcare_previewvideo-it.mp4";
import videoMblIt from "../../images/pcare_previewvideo_mbl-it.mp4";


const VideoAppSection = ({ countryCode, openVideoModal, isDesktopDown, mobileAppDatas, sectionVideoApp, reference }) => {
    const openModal = () => {
        openVideoModal();
        window.dataLayer.push({
            event: "GAevent",
            eventName: "call_to_action",
            cta_name: "open-video",
        });
    };

    const openModalMbl = () => {
        if (isDesktopDown) {
            openVideoModal();
            window.dataLayer.push({
                event: "GAevent",
                eventName: "call_to_action",
                cta_name: "open-video",
            });
        }
    };

    return (
        <div id="app" className={style.sectionVideoApp} ref={reference}>
            <div className={style.wrapperVideoContent}>
                <div className={style.videoContentTitle}>
                    <p dangerouslySetInnerHTML={{ __html: sectionVideoApp.title }}/>
                </div>

                <div className={style.wrapperVideoContent_container} aria-hidden="true" onClick={() => openModalMbl()}>
                    <div className={style.wrapperVideoMbl}>
                        <video autoPlay loop muted playsInline>
                            {countryCode === 'it' ?
                                <source src={videoMblIt} />
                                :
                                <source src={videoMbl} />
                            }
                        </video>

                        <div className={style.iconVideo}>
                            <svg width="77" height="50" viewBox="0 0 77 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30.6045 34.192L51.3584 24.2752L30.6045 14.2891V34.192Z" fill="white"/>
                                <path opacity="0.12" d="M30.6045 14.2891L48.8018 25.4957L51.3584 24.2752L30.6045 14.2891Z" fill="#420000"/>
                                <path d="M76.1732 10.8427C76.1732 10.8427 75.4213 5.9606 73.1203 3.81081C70.2027 0.988341 66.9242 0.974471 65.4278 0.808036C54.6749 0.09375 38.553 0.09375 38.553 0.09375H38.5229C38.5229 0.09375 22.4011 0.09375 11.6481 0.808036C10.1518 0.974471 6.87324 0.988341 3.95566 3.81081C1.65469 5.9606 0.902734 10.8427 0.902734 10.8427C0.902734 10.8427 0.135742 16.5709 0.135742 22.306V27.6804C0.135742 33.4155 0.902734 39.1437 0.902734 39.1437C0.902734 39.1437 1.65469 44.0258 3.95566 46.1756C6.88076 48.9981 10.7157 48.9079 12.4227 49.2061C18.5661 49.747 38.538 49.9204 38.538 49.9204C38.538 49.9204 54.6749 49.8996 65.4278 49.1784C66.9317 49.0119 70.2027 48.9981 73.1203 46.1756C75.4213 44.0258 76.1732 39.1437 76.1732 39.1437C76.1732 39.1437 76.9402 33.4086 76.9402 27.6804V22.306C76.9402 16.5709 76.1732 10.8427 76.1732 10.8427ZM30.6049 34.1922V14.2893L51.3588 24.2754L30.6049 34.1922Z" fill="url(#paint0_linear_5827_69679)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_5827_69679" x1="38.538" y1="0.09375" x2="38.538" y2="49.9204" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#E52D27"/>
                                        <stop offset="1" stopColor="#BF171D"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                    </div>

                    <div className={style.wrapperVideoDsk} onClick={() => openVideoModal()}>
                        <video autoPlay loop muted playsInline>
                            {countryCode === 'it' ?
                                <source src={videoIt} />
                                :
                                <source src={video} />
                            }
                        </video>

                        <div className={style.wrapperVideoDskCta}>
                            {/*<svg width="77" height="50" viewBox="0 0 77 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30.6045 34.192L51.3584 24.2752L30.6045 14.2891V34.192Z" fill="white"/>
                                <path opacity="0.12" d="M30.6045 14.2891L48.8018 25.4957L51.3584 24.2752L30.6045 14.2891Z" fill="#420000"/>
                                <path d="M76.1732 10.8427C76.1732 10.8427 75.4213 5.9606 73.1203 3.81081C70.2027 0.988341 66.9242 0.974471 65.4278 0.808036C54.6749 0.09375 38.553 0.09375 38.553 0.09375H38.5229C38.5229 0.09375 22.4011 0.09375 11.6481 0.808036C10.1518 0.974471 6.87324 0.988341 3.95566 3.81081C1.65469 5.9606 0.902734 10.8427 0.902734 10.8427C0.902734 10.8427 0.135742 16.5709 0.135742 22.306V27.6804C0.135742 33.4155 0.902734 39.1437 0.902734 39.1437C0.902734 39.1437 1.65469 44.0258 3.95566 46.1756C6.88076 48.9981 10.7157 48.9079 12.4227 49.2061C18.5661 49.747 38.538 49.9204 38.538 49.9204C38.538 49.9204 54.6749 49.8996 65.4278 49.1784C66.9317 49.0119 70.2027 48.9981 73.1203 46.1756C75.4213 44.0258 76.1732 39.1437 76.1732 39.1437C76.1732 39.1437 76.9402 33.4086 76.9402 27.6804V22.306C76.9402 16.5709 76.1732 10.8427 76.1732 10.8427ZM30.6049 34.1922V14.2893L51.3588 24.2754L30.6049 34.1922Z" fill="url(#paint0_linear_5827_69679)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_5827_69679" x1="38.538" y1="0.09375" x2="38.538" y2="49.9204" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#E52D27"/>
                                        <stop offset="1" stop-color="#BF171D"/>
                                    </linearGradient>
                                </defs>
                            </svg>*/}
                        </div>
                    </div>
                </div>
            </div>

            <MobileAppDownload
                sectionDatas={mobileAppDatas}
                countryCode={countryCode}
            />
        </div>
    )
}


export default VideoAppSection;