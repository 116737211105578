import * as React from "react";
import * as style from "./style.module.scss";

const IframeTires = ({sectionDatas}) => {
    const iframeSrc = sectionDatas.url;

    return (
        <div className={style.sectionIframeTires}>
            <iframe src={iframeSrc} />
        </div>
    )
}

export default IframeTires;