import * as React from "react";
import * as style from "./style.module.scss";
import { StaticImage } from "gatsby-plugin-image";

const PirelliGift = ({countryCode, sectionDatas}) => {
    return  (
        <div className={style.sectionPirelliFamily}>
            <div className={style.wrapperPirelliFamilyContent}>

                <div className={style.containerImage}>
                    <StaticImage src="../../images/car-icon.png" alt="Pirelli" title="car icon" objectFit="contain" />
                </div>

                <div className={style.wrapperContent}>
                    <div className={style.contentDescription}>
                        <p className={style.contentDescription_title} dangerouslySetInnerHTML={{ __html: sectionDatas.title }} />

                        <p className={style.contentDescription_infoText} dangerouslySetInnerHTML={{ __html: sectionDatas.sectionDescription }} />
                    </div>

                    <div className={style.wrapperCta}>
                        <a href={`${process.env.GATSBY_ONBOARDING_PORTAL_LINK}${sectionDatas.ctaLink}`}
                           rel="noreferrer"
                           target="_blank"
                           onClick={(e) => {
                               window.dataLayer.push({
                                   event: "GAevent",
                                   eventName: "call_to_action",
                                   cta_name: "start-funnel-superior",
                               });
                           }}
                        >
                            <div className={style.wrapperCtaContent}>
                                <p dangerouslySetInnerHTML={{ __html: sectionDatas.ctaText }} />

                                <svg focusable="false" data-key="forward" aria-hidden="true" viewBox="0 0 52 52">
                                    <g>
                                        <path d="M3.4 29h33.2c.9 0 1.3 1.1.7 1.7l-9.6 9.6c-.6.6-.6 1.5 0 2.1l2.2 2.2c.6.6 1.5.6 2.1 0L49.5 27c.6-.6.6-1.5 0-2.1L32 7.4c-.6-.6-1.5-.6-2.1 0l-2.1 2.1c-.6.6-.6 1.5 0 2.1l9.6 9.6c.6.7.2 1.8-.7 1.8H3.5c-.8 0-1.5.6-1.5 1.4v3c0 .8.6 1.6 1.4 1.6z"></path>
                                    </g>
                                </svg>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PirelliGift;