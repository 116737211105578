import React from "react";
import Logo from "../Logo";
import * as style from "./style.module.scss";
import ChooseSerivece from "../ChooseServices";

const HeroInner = (props) => {
	const { tabletDown, openVideoModal, chooseServices, heroInnerIntro, mobileUp} = props;
	const openModal = () => {
		openVideoModal();
		window.dataLayer.push({
			event: "GAevent",
			eventName: "call_to_action",
			cta_name: "open-video",
		});
	};
	const openModalMbl = () => {
		if (tabletDown) {
			openVideoModal();
			window.dataLayer.push({
				event: "GAevent",
				eventName: "call_to_action",
				cta_name: "open-video",
			});
		}
	};

	return (
		<div className={style.sectionHeroInner}>
			<div className={style.heroInner} id="home">

			<div className={style.content_container}>
				{tabletDown &&
					<div className={style.content_logos}>
						<Logo height={"30px"}/>
					</div>
				}

				<div className={style.wrapperVisualTitle}>
					<p className={style.sectionTitle} dangerouslySetInnerHTML={{ __html: heroInnerIntro.title }}/>

					<p className={style.sectionSubtitle} dangerouslySetInnerHTML={{ __html: heroInnerIntro.sectionDescription }}/>

					<p className={style.sectionDescriptionNote} dangerouslySetInnerHTML={{ __html: heroInnerIntro.sectionDescriptionNote }}/>
				</div>
			</div>

			<ChooseSerivece
				mobileUp={mobileUp}
				chooseServices={chooseServices}
			/>
		</div>
		</div>
	);
};
export default HeroInner;
