import React from "react";
import CloseIcon from "../Icons/CloseIcon";
import * as style from "./style.module.scss";

const ModalVideo = (props) => {
	const { closeVideoModal, isOpen, sectionDatas } = props;
	return (
		<div className={`${style.modal_container} ${isOpen ? style.show : ""}`}>
			<div className={style.modal_container_background} onClick={closeVideoModal} aria-hidden="true" />
			<CloseIcon className={style.modal_closeIcon} onClick={closeVideoModal} />
			<div className={style.modal_body}>
				{isOpen && (
					<iframe
						src={sectionDatas.url}
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					/>
				)}
			</div>
		</div>
	);
};

export default ModalVideo;
