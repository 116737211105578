// extracted by mini-css-extract-plugin
export var sectionThreePlans = "style-module--sectionThreePlans--2V7yp";
export var slidesContainer = "style-module--slidesContainer--3Qq5b";
export var wrapperSectionTitle = "style-module--wrapperSectionTitle--2QcJc";
export var containerSwiper = "style-module--containerSwiper--114DZ";
export var threePlansSwiper_slide = "style-module--threePlansSwiper_slide--rnxxp";
export var onboardingLink = "style-module--onboardingLink--1wLLx";
export var slideContentWrapper = "style-module--slideContentWrapper--6C0Us";
export var badgeRecommended = "style-module--badgeRecommended--3bElR";
export var wrapperTextInfos = "style-module--wrapperTextInfos--1MS2w";
export var wrapperSlideTitle = "style-module--wrapperSlideTitle--12bcA";
export var slideTitle = "style-module--slideTitle--cktEM";
export var wrapperSlideDescritpion = "style-module--wrapperSlideDescritpion--3cgCJ";
export var contentWrapper = "style-module--contentWrapper--7ZE7s";
export var slideContent = "style-module--slideContent--1iZrc";
export var europeAssistance = "style-module--europeAssistance--Hveev";
export var ulPlusIcon = "style-module--ulPlusIcon--1EGX1";
export var slideContentListBox = "style-module--slideContentListBox--qwa9K";
export var infoNews = "style-module--infoNews--CGq3I";
export var tagNews = "style-module--tagNews--349b3";
export var offerNews = "style-module--offerNews--3ifI6";
export var wrapperContent = "style-module--wrapperContent--2-u28";
export var boxImage = "style-module--boxImage--1pGA3";
export var chargeEvImage = "style-module--chargeEvImage--CNBX3";
export var tryNewOffer = "style-module--tryNewOffer--Ez0fU";
export var priceContainer = "style-module--priceContainer--hDDuz";
export var basePrice = "style-module--basePrice--2DJ88";
export var freeOffer = "style-module--freeOffer--2S_ZZ";
export var wrapperChooseOffer = "style-module--wrapperChooseOffer--2La7t";
export var threePlansSwiper = "style-module--threePlansSwiper--1nYNM";
export var dskSlidesContainer = "style-module--dskSlidesContainer--45LSV";
export var note = "style-module--note--3a5c-";
export var dskVisual = "style-module--dskVisual--3Yz_6";