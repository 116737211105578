import React, { useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import Faq from "../FAQ";
import Footer from "../Footer";
import HeroInner from "../HeroInner";
import Navbar from "../Navbar";
import ThreePlans from "../ThreePlans";
import VideoAppSection from "../VideoAppSection";
import "../../css/typography.scss";
import StickyElement from "../StickyElement";
import MblwhatsappBtn from "../MblwhatsappBtn";
import IframeTires from "../IframeTires";
import ModalVideo from "../ModalVideo";
import ogImage from "../../images/pirellicare-share-image.png";
import PirelliFamily from "../PirelliFamily";
import PlansCustomization from "../PlansCustomization";
import PlansYourdrivingStyle from "../PlansYourdrivingStyle";

// styles
const Main = styled.main`
	font-family: Gotham, -apple-system, Roboto, sans-serif, serif;
	box-sizing: border-box;
	max-width: 2560px;
	margin: 0 auto;
	position: relative;
`;

// markup
const BodyComponents = (props) => {
	const locationHref = props.location.href;
	const faqIframteUrl = `${process.env.GATSBY_SITE_URL}/${props.dataTexts.countryCode}/faq/`;
	const [pageLoaded, setPageLoaded] = useState({ load: false });
	const [isMobile, setIsMobile] = useState(false);
	const [tabletDown, setTabletDown] = useState(false);
	const [isDesktopDown, setIsDesktopDown] = useState(false);
	const [isDesktop, setIsDesktop] = useState(false);
	const [mobileUp, setMobileUp] = useState(false);
	/*const [mobileDown, setMobileDown] = useState(false);*/
	const [modalVideoOpen, setModalVideoOpen] = useState(false);

	useEffect(() => {
		setIsMobile(window.innerWidth < 480);
		setMobileUp(window.innerWidth >= 768);
		/*setMobileDown(window.innerWidth < 768);*/
		setTabletDown(window.innerWidth < 1024);
		setIsDesktopDown(window.innerWidth < 1200);
		setIsDesktop(window.innerWidth >= 1200);

		setPageLoaded({ load: true });

		window.addEventListener("resize", () => {
			setTimeout(() => {
				setIsMobile(window.innerWidth < 480);
				setMobileUp(window.innerWidth > 768);
				/*setMobileDown(window.innerWidth < 768);*/
				setTabletDown(window.innerWidth < 1024);
				setIsDesktopDown(window.innerWidth < 1200);
				setIsDesktop(window.innerWidth >= 1200);
			}, 1);
		});
	}, []);

	const refDelearLocator = useRef();
	const refFaq = useRef();
	const refSectionApp = useRef();

	const handleScroll = (ref) => {
		let goto = null;
		if (ref === "dealerlocator") {
			goto = refDelearLocator.current;
		} else if (ref === "faq") {
			goto = refFaq.current;
		}

		if (goto !== null) {
			window.scrollTo({
				top: goto.offsetTop,
				left: 0,
				behavior: "smooth",
			});
		}
	};

	useEffect(() => {
		let urlId = locationHref.split("/").pop();

		const scrollToSectionDelayed = (urlId, sectionTarget) => {
			if (locationHref.includes(urlId)) {
				setTimeout(() => {
					window.scroll({
						top: sectionTarget.current.offsetTop,
						behavior: "smooth",
					});
				}, 1000);
			}
		};

		if (pageLoaded.load === true) {
			switch (urlId) {
				case "#faq":
					scrollToSectionDelayed(urlId, refFaq);
					break;
				case "#app":
					scrollToSectionDelayed(urlId, refSectionApp);
					break;
			}
		}
	}, [pageLoaded]);
	const analyticsEvent = `
    analyticsEvent = function() {}; 
    analyticsVPV = function() {}; 
    analyticsClearVPV = function() {}; 
    analyticsForm = function(){}; 
    analyticsSocial = function(){}; 
    window.dataLayer = window.dataLayer || [];
    `;
	return (
		<Main>
			<Helmet>
				<title>{props.dataTexts.metaTitle}</title>
				<meta name="description" content={props.dataTexts.metaDescription} />
				<meta name="twitter:card" content={`${process.env.GATSBY_SITE_URL}${ogImage}`} />
				<meta property="og:image" content={`${process.env.GATSBY_SITE_URL}${ogImage}`} />
				<script id="analytics-script">{analyticsEvent}</script>
				{pageLoaded.load === true && locationHref !== faqIframteUrl && (
					<script id="cookiescript" src="https://tyre-assets.pirelli.com/staticfolder/Tyre/cookie-bar/1.8/cookies-gtm.min.js?countrycode=it_IT&gtmcontainer=GTM-56VJ7FL&customclass=pirellicare" />
				)}
			</Helmet>

			<div>
				<StickyElement tabletDown={tabletDown} isDesktop={isDesktop} stickyElementData={props.dataTexts.stickySection} countryCode={props.dataTexts.countryCode} />

				<MblwhatsappBtn />

				<Navbar countryCode={props.dataTexts.countryCode} supports={props.dataTexts.supports} handleScroll={handleScroll} />

				<HeroInner
					countryCode={props.dataTexts.countryCode}
					mobileDown={isMobile}
					tabletDown={tabletDown}
					desktopUp={isDesktop}
					desktopDown={isDesktopDown}
					mobileUp={mobileUp}
					openVideoModal={() => {
						setModalVideoOpen(true);
					}}
					paragraph={props.dataTexts.newBuySolution}
					videoParagraphBtn={props.dataTexts.videoParagraphBtn}
					cardGroupSubtitle={props.dataTexts.cardGroupSubtitle}
					chooseServices={props.dataTexts.chooseServices}
					servicesInternalCards={props.dataTexts.servicesInternalCards}
					heroInnerIntro={props.dataTexts.heroInnerIntro}
				/>

				<ThreePlans slides={props.dataTexts.threePlansSlides} section={props.dataTexts.threePlansSection} isDesktop={isDesktop} countryCode={props.dataTexts.countryCode} />

				{isMobile && <PirelliFamily countryCode={props.dataTexts.countryCode} sectionDatas={props.dataTexts.pirelliFamily} />}

				<PlansYourdrivingStyle countryCode={props.dataTexts.countryCode} datas={props.dataTexts.PlansYourDrivingStyle} isDesktop={isDesktop} />

				<VideoAppSection
					reference={refSectionApp}
					openVideoModal={() => {
						setModalVideoOpen(true);
					}}
					countryCode={props.dataTexts.countryCode}
					isDesktopDown={isDesktopDown}
					sectionVideoApp={props.dataTexts.sectionVideoApp}
					mobileAppDatas={props.dataTexts.mobileDownloadApp}
				/>

				<PlansCustomization countryCode={props.dataTexts.countryCode} datas={props.dataTexts.PlansCustomization} isDesktop={isDesktop} handleScroll={handleScroll} />

				{/*
        <PirelliBanner {...props.dataTexts.bannerInsegnaAnno} />
*/}

				<div ref={refDelearLocator}>
					<IframeTires sectionDatas={props.dataTexts.dealerLocator} />
				</div>

				<div ref={refFaq}>
					<Faq
						countryCode={props.dataTexts.countryCode}
						faqList={props.dataTexts.faq}
						labelScrollTo={props.dataTexts.labelscroolTo}
						selectedDealers={props.dataTexts.selectedDealers}
						faqTitleSection={props.dataTexts.faqTitleSection}
						otherQuestions={props.dataTexts.otherQuestions}
						notes={props.dataTexts.notes}
						handleScroll={handleScroll}
					/>
				</div>
			</div>

			<Footer footerDatas={props.dataTexts.footer} />

			<ModalVideo sectionDatas={props.dataTexts.modalVideo} isOpen={modalVideoOpen} closeVideoModal={() => setModalVideoOpen(false)} />
		</Main>
	);
};

export default BodyComponents;
