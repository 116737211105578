// extracted by mini-css-extract-plugin
export var mobileAppSection = "style-module--mobile-app-section--LKgag";
export var dskSetionTitle = "style-module--dskSetionTitle--3p9Hv";
export var mblImagesSectionPreview = "style-module--mblImagesSectionPreview--3BTnD";
export var wrapperMobileApp = "style-module--wrapper-mobile-app--2BPxy";
export var mobileAppInfosBox = "style-module--mobile-app-infos-box--3KytP";
export var infosBoxTitle = "style-module--infos-box-title--NN71l";
export var title = "style-module--title--1zvnY";
export var infosCtaBox = "style-module--infosCtaBox--3N_zM";
export var wrapperCtaContent = "style-module--wrapperCtaContent--zNtQA";
export var infosBoxMobileStores = "style-module--infos-box-mobile-stores--14Kh5";
export var mobileStores = "style-module--mobile-stores--2Yfw2";
export var boxQrCode = "style-module--box-qr-code--2Fj46";
export var mobileAppBoxImage = "style-module--mobile-app-box-image--1Rhae";