import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import * as style from "./style.module.scss";

const MobileAppDownload = ({sectionDatas, countryCode}) => {
    const imagePath = "../../images/";

    return (
        <div className={style.mobileAppSection}>
            <div className={style.dskSetionTitle}>
                <p dangerouslySetInnerHTML={{ __html: sectionDatas.dskSectionTitle }}/>
            </div>

            <div className={style.mblImagesSectionPreview}>
                {
                    countryCode === 'it' ?
                        <StaticImage src={`${imagePath}section-mobile-app-mbl-it.png`} alt="preview app mobile"/> :
                        <StaticImage src={`${imagePath}section-mobile-app-mbl-en.png`} alt="preview app mobile"/>
                }
            </div>

            <div className={style.wrapperMobileApp}>
                <div className={style.mobileAppInfosBox}>
                    <div className={style.infosBoxTitle}>
                        <p className={style.title} dangerouslySetInnerHTML={{ __html: sectionDatas.title  }} />
                    </div>

                    <div className={style.infosCtaBox}>
                        <a href="https://www.pirellicare.com/app" rel="noreferrer" target="_blank">
                            <div className={style.wrapperCtaContent}>
                                <p dangerouslySetInnerHTML={{ __html: sectionDatas.ctaText }} />

                                <svg focusable="false" data-key="forward" aria-hidden="true" viewBox="0 0 52 52">
                                    <g>
                                        <path d="M3.4 29h33.2c.9 0 1.3 1.1.7 1.7l-9.6 9.6c-.6.6-.6 1.5 0 2.1l2.2 2.2c.6.6 1.5.6 2.1 0L49.5 27c.6-.6.6-1.5 0-2.1L32 7.4c-.6-.6-1.5-.6-2.1 0l-2.1 2.1c-.6.6-.6 1.5 0 2.1l9.6 9.6c.6.7.2 1.8-.7 1.8H3.5c-.8 0-1.5.6-1.5 1.4v3c0 .8.6 1.6 1.4 1.6z"></path>
                                    </g>
                                </svg>
                            </div>
                        </a>
                    </div>

                    <div className={style.infosBoxMobileStores}>
                        <div className={style.mobileStores}>
                            <a href="https://apps.apple.com/app/pirelli-care/id1567220418" target="_blank" rel='noreferrer'>
                                {
                                    countryCode === 'it' ?
                                        <StaticImage src={`${imagePath}app_store_logo.png`} alt="app store logo"/> :
                                        <StaticImage src={`${imagePath}app_store_logo_en.png`} alt="app store logo"/>
                                }
                            </a>

                            <a href="https://play.google.com/store/apps/details?id=com.pirelli.care" target="_blank" rel='noreferrer'>
                                {
                                    countryCode === 'it' ?
                                        <StaticImage src={`${imagePath}gplay_store_logo.png`} alt="google play store logo"/> :
                                        <StaticImage src={`${imagePath}gplay_store_logo_en.png`} alt="google play store logo"/>
                                }
                            </a>
                        </div>

                        <div className={style.boxQrCode}>
                            <StaticImage src={`${imagePath}qr_code_store.jpg`} alt="qr code store"/>
                        </div>
                    </div>
                </div>

                <div className={style.mobileAppBoxImage}>
                    {
                        countryCode === 'it' ?
                            <StaticImage src={`${imagePath}img_app_mobile_preview_it.png`} alt="preview app mobile"/> :
                            <StaticImage src={`${imagePath}img_app_mobile_preview_en.png`} alt="preview app mobile"/>
                    }
                </div>
            </div>
        </div>
    )
}

export default MobileAppDownload;