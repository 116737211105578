import * as React from "react";
import * as style from "./style.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { StaticImage } from "gatsby-plugin-image";

const ThreePlans = ({ slides, section, isDesktop, countryCode }) => {
  const imagePath = "../../images/";

  let list = slides.map((slide) => (
    <SwiperSlide className={style.threePlansSwiper_slide} key={slide.id}>
      <a
        className={style.onboardingLink}
        href={`${process.env.GATSBY_ONBOARDING_PORTAL_LINK}${slide.link}`}
        rel="noopener noreferrer"
        target="_blank"
        aria-label="link to package"
        onClick={(e) => {
          window.dataLayer.push({
            event: "GAevent",
            eventName: "call_to_action",
            cta_name: `start-funnel-${slide.id}`,
          });
        }}
      >
        <div className={style.slideContentWrapper}>
          {slide.recommended && (
            <div className={style.badgeRecommended}>
              <p dangerouslySetInnerHTML={{ __html: slide.recommended }} />
            </div>
          )}

          <div className={style.wrapperTextInfos}>
            <div className={style.wrapperSlideTitle}>
              <p
                className={style.slideTitle}
                dangerouslySetInnerHTML={{ __html: slide.title }}
              />
            </div>

            <div className={style.wrapperSlideDescritpion}>
              <p dangerouslySetInnerHTML={{ __html: slide.textDescription }} />
            </div>

            <div className={style.contentWrapper}>
              <div className={style.slideContent}>
                {Array.from(slide.textContent).map((item, index) => (
                  <div className={style.slideContentListBox} key={index}>
                    {item.sectionTitle && <p>{item.sectionTitle}</p>}

                    <ul>
                      {item.list.map((element, index) => (
                        <li
                          key={index}
                          className={`${
                            element.class === true ? style.europeAssistance : ""
                          }`}
                          dangerouslySetInnerHTML={{ __html: element.text }}
                        />
                      ))}
                    </ul>
                  </div>
                ))}
              </div>

              <div className="footerWrapper">
                {slide.plusList && (
                  <div className={style.slideContent}>
                    <p
                      dangerouslySetInnerHTML={{ __html: slide.plusListTitle }}
                    />

                    <ul
                      className={style.ulPlusIcon}
                      dangerouslySetInnerHTML={{ __html: slide.plusList }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

            {slide.tagNews &&
                <div className={style.infoNews}>
                    <div className={style.tagNews}>
                        <p dangerouslySetInnerHTML={{__html: slide.tagNews}}/>
                    </div>

                    {slide.basicNews ?
                        <div className={style.offerNews}>
                            <div className={style.wrapperContent}>
                                <div className={style.boxImage}></div>
                                <p dangerouslySetInnerHTML={{__html: slide.basicNews}}/>
                            </div>

                            <div className={style.chargeEvImage}>
                                <StaticImage src={`${imagePath}icona-estensione-garanzia.png`} alt="icon garanzia salva pneumaticox"/>
                            </div>
                        </div> :

                        <p className={style.tryNewOffer} dangerouslySetInnerHTML={{__html: slide.newTryOffer}}/>
                    }
                </div>
            }
        </div>
      </a>
    </SwiperSlide>
  ));

  return (
    <div className={style.sectionThreePlans}>
      <div className={style.slidesContainer}>
        <div className={style.wrapperSectionTitle}>
          <p dangerouslySetInnerHTML={{ __html: section.sectionTitle }} />
        </div>

        <div className={style.containerSwiper}>
          {isDesktop ? (
            <div className={style.dskSlidesContainer}>{list}</div>
          ) : (
            <Swiper
              className={style.threePlansSwiper}
              slidesPerView={1.1}
              centeredSlides={true}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              breakpoints={{
                600: {
                  slidesPerView: 2,
                },
                1920: {
                  slidesPerView: 3,
                },
              }}
            >
              {list.reverse()}
            </Swiper>
          )}
        </div>

        {/*       <p
          className={style.note}
          dangerouslySetInnerHTML={{ __html: section.note }}
        />*/}
      </div>

      <div className={style.dskVisual}>
        <StaticImage
          src={"../../images/big-mongolfiera.png"}
          alt={"section plans visual"}
        />
      </div>
    </div>
  );
};

export default ThreePlans;
